body {
  padding-top: 56px;
  font-family: "MS Sans Serif", Geneva, sans-serif;
}

#root {
  white-space: pre-line;
}

.text-manjari {
  font-family: "Manjari", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Manjari", sans-serif;
  letter-spacing: -1px;
}

.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.breadcrumb-item a {
  color: #ff8c00 !important;
  text-decoration: none !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #137bca !important;
  border: 0 !important;
}

.btn-warning {
  background-color: #ff8c00 !important;
  color: #fff !important;
  border: 0 !important;
}

.btn-danger {
  background-color: #fc0f28 !important;
  color: #fff !important;
  border: 0 !important;
}

.btn-success {
  background-color: #00cd3b !important;
  color: #fff !important;
  border: 0 !important;
}

.img-rotate {
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.img-rotate:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.link {
  text-decoration: none !important;
  color: white;
  font-size: 10px;
}

.link:hover {
  color: white;
}

.carousel-caption-top {
  position: absolute;
  right: 15%;
  left: 15%;
  top: 20%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-caption-bottom {
  position: absolute;
  left: 15%;
  bottom: 20%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 479px) {
  .img-responsive {
    max-width: 50%;
  }
  .carousel-caption-bottom {
    position: absolute;
    right: 15%;
    top: 20%;
    left: 15%;
  }

  .backblack {
    width:100%!important;
  }
}

.backblack {
  background-color: rgba(34, 34, 34, 0.81);
  width:50%;
  margin:auto;
  padding:5px;
}